/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './EmailScreen.module.scss';
import EmailScreenSuggestionsList from './EmailScreenSuggestionsList';
import Button from '../common/Button/Button';
import Checkbox from '../common/Checkbox/Checkbox';
import Input from '../common/Input/Input';

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
});

export type EmailScreenProps = {
  onSubmit: (
    data: { email: string; emailConsent: boolean },
  ) => void
};

const Mail = ({ onSubmit }: EmailScreenProps) => {
  const {
    getFieldProps,
    values,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: '',
      emailConsent: true,
    },
    validationSchema,
    onSubmit: async (submitValues) => {
      await onSubmit(submitValues);
    },
  });

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.container}>
        <h3 className={styles.subtitle}>
          Enter email address to get access to personalized love reading and
          discover relationship experts you were matched with
        </h3>
        <ul className={styles.formControlList}>
          <li className={styles.formControlListItem}>
            <Input
              {...getFieldProps('email')}
              error={touched.email && !!errors.email}
              errorText={errors.email}
              className={styles.input}
              placeholder="Your best email address"
            />
            <EmailScreenSuggestionsList
              input={values.email}
              onSelect={(value) => setFieldValue('email', value)}
            />
          </li>
          <li className={styles.formControlListItem}>
            <Checkbox
              className={styles.checkbox}
              {...getFieldProps('emailConsent')}
              checked={getFieldProps('emailConsent').value}
              label="I would like to receive my report and personalised offers & updates from Nebula via this email."
            />
          </li>
        </ul>
      </div>
      <div className={styles.block}>
        <Button type="submit" disabled={isSubmitting} text="Continue" />
        <div className={styles.tipWrapper}>
          We respect your privacy and are committed to protecting your personal
          data
        </div>
      </div>
    </form>
  );
};

export default Mail;
