import React from 'react';
import done from 'src/assets/images/done.png';
import styles from './LoaderList.module.scss';
import { ILoaderList } from './ILoaderList';
import './Loader.scss';

const LoaderList: React.FC<ILoaderList> = ({ list }) => (
  <ul className={styles.list}>
    {list.map((item, idx) => (
      <li key={item}>
        {item}
        <img className={`img-${idx}`} src={done} alt="done" />
      </li>
    ))}
  </ul>
);

export default LoaderList;
