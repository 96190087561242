import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useGetPopupState from '../../hooks/useGetPopupState';
import Purchase from '../PostQuiz/Purchase/Purchase';
import PurchaseFailed from '../PostQuiz/PurchaseFailed/PurchaseFailed';
import { IPopUpProps } from './IPopUpProps';

const popups: Record<string, FC<IPopUpProps>> = {
  purchase: Purchase,
  purchaseFailed: PurchaseFailed,
};

const PopUpContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const { mountedPopup, isOpened } = useGetPopupState();

  if (!mountedPopup) {
    return null;
  }

  const Component = popups[mountedPopup];

  if (!Component) {
    return null;
  }

  const onClose = () => {
    history.push(location.pathname);
  };

  return <Component isOpened={isOpened} onClose={onClose} />;
};

export default PopUpContainer;
