import React from 'react';
import { ReactComponent as Img } from 'src/assets/images/quiz/trust.svg';
import QuizContent from 'src/components/QuizContent/QuizContent';
import RadioChoice from 'src/components/RadioChoice/RadioChoice';
import { ROUTES } from '../../constants/routes';
import PensiveImg from '../../assets/images/emojies/pensive.svg';
import NeutralImg from '../../assets/images/emojies/neutral.svg';
import RelievedImg from '../../assets/images/emojies/relieved.svg';

const choices = [
  { text: 'Yes, absolutely', img: PensiveImg, id: 1 },
  { text: 'Not sure', img: NeutralImg, id: 2 },
  { text: 'No, I am very open', img: RelievedImg, id: 3 },
];

const Trust = () => (
  <QuizContent
    title="Do you tend to get disappointed with people often?"
    img={<Img title="trust" />}
    step={4}
    radio
    routeBack={ROUTES.DETAILS}
  >
    <RadioChoice route={ROUTES.MAIL} choices={choices} name="trust" />
  </QuizContent>
);
export default Trust;
