import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styles from './Guarantee.module.scss';
import mc from '../../../assets/images/postQuiz/guarantee/mcafee.svg';
import mcMob from '../../../assets/images/postQuiz/guarantee/mcMob.svg';
import ssl from '../../../assets/images/postQuiz/guarantee/ssl.svg';
import sslMob from '../../../assets/images/postQuiz/guarantee/sslMob.svg';
import visa from '../../../assets/images/postQuiz/guarantee/visa.svg';
import visaMob from '../../../assets/images/postQuiz/guarantee/visaMob.svg';
import union from '../../../assets/images/postQuiz/guarantee/union.svg';
import unionMob from '../../../assets/images/postQuiz/guarantee/unionpayMob.svg';
import amex from '../../../assets/images/postQuiz/guarantee/amex.svg';
import amexMob from '../../../assets/images/postQuiz/guarantee/amexMob.svg';
import discover from '../../../assets/images/postQuiz/guarantee/discover.svg';
import discoverMob from '../../../assets/images/postQuiz/guarantee/discoverMob.svg';
import mastercard from '../../../assets/images/postQuiz/guarantee/mastercard.svg';
import mastercardMob from '../../../assets/images/postQuiz/guarantee/mastercardMob.svg';
import maestro from '../../../assets/images/postQuiz/guarantee/maestro.svg';
import maestroMob from '../../../assets/images/postQuiz/guarantee/maestroMob.svg';
import jcb from '../../../assets/images/postQuiz/guarantee/jcb.svg';
import jcbMob from '../../../assets/images/postQuiz/guarantee/jcbMob.svg';
import paypal from '../../../assets/images/postQuiz/guarantee/paypal.svg';
import paypalMob from '../../../assets/images/postQuiz/guarantee/paypalMob.svg';

const Guarantee = () => {
  const isDesktop = useMediaQuery('(min-width: 1025px)');
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Guaranteed security payments</h2>
      <div className={styles.background}>
        <div className={styles.block}>
          <div className={styles.img}>
            <img src={isDesktop ? mc : mcMob} alt="McAfee" />
            <img src={isDesktop ? ssl : sslMob} alt="ssl" />
            <img src={isDesktop ? visa : visaMob} alt="visa" />
            <img src={isDesktop ? union : unionMob} alt="union" />
            <img src={isDesktop ? amex : amexMob} alt="amex" />
          </div>
          <div className={styles.img}>
            <img src={isDesktop ? discover : discoverMob} alt="discover" />
            <img src={isDesktop ? mastercard : mastercardMob} alt="mastercard" />
            <img src={isDesktop ? maestro : maestroMob} alt="maestro" />
            <img src={isDesktop ? jcb : jcbMob} alt="jcb" />
            <img src={isDesktop ? paypal : paypalMob} alt="paypal" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guarantee;
