import { useEffect } from 'react';
import useServices from 'src/hooks/useServices';

export default function useFlow() {
  const { analyticsService } = useServices();

  useEffect(() => {
    analyticsService.flowStartOpen();
  }, [analyticsService]);
}
