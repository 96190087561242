import { AppThunk } from '../types/AppThunk';

export * from './selectors';

export const AGREE_PRIVATE_DATA_COLLECTION = 'AGREE_PRIVATE_DATA_COLLECTION';

export interface CookiesAgreementState {
  isPrivateDataCollectionAgreed?: boolean;
}

const initialState: CookiesAgreementState = {
  isPrivateDataCollectionAgreed: false,
};

const agreePrivateDataCollection = () => ({
  type: AGREE_PRIVATE_DATA_COLLECTION,
});

export const doAgreePrivateDataCollection = (): AppThunk => (dispatch, getState, context) => {
  const { cookiesService } = context;
  cookiesService.agreePrivateDataCollection();
  dispatch(agreePrivateDataCollection());
};

export type CookiesAgreementActions = ReturnType<typeof agreePrivateDataCollection>

export function cookiesAgreementReducer(state = initialState, action: CookiesAgreementActions) {
  switch (action.type) {
    case AGREE_PRIVATE_DATA_COLLECTION:
      return { ...state, isPrivateDataCollectionAgreed: true };
    default:
      return state;
  }
}
