import React from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'src/components/Loader/Loader';
import LoaderList from 'src/components/LoaderList/LoaderList';
import { ROUTES } from 'src/constants/routes';

import styles from './Processing.module.scss';

const Processing = () => {
  const history = useHistory();

  const list = [
    'Designing your unique astronomical snapshots',
    'Analyzing your natal charts',
    'Comparing your astrology readings',
    'Picking the best insights',
    'Preparing your compatibility report',
  ];

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        Hold on a second... We are creating a report for you that will reveal
        your compatibility with other zodiac signs
      </h1>
      <Loader onComplete={() => history.push(ROUTES.POST_QUIZ)} />
      <LoaderList list={list} />
    </div>
  );
};

export default Processing;
