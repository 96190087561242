import { combineReducers } from 'redux';
import { locationReducer } from 'src/location/store';
import { cookiesAgreementReducer } from '../cookiesAgreement';
import { userReducer } from '../user';

const rootReducer = combineReducers({
  cookiesAgreement: cookiesAgreementReducer,
  user: userReducer,
  location: locationReducer,
});

export type RootInitialState = ReturnType<typeof rootReducer>;

export default rootReducer;
