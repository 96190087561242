import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Flow from 'src/pages/Flow/Flow';
import Gender from 'src/pages/Gender/Gender';
import Overthink from 'src/pages/Overthink/Overthink';
import Details from 'src/pages/Details/Details';
import Trust from 'src/pages/Trust/Trust';
import Processing from 'src/pages/Processing/Processing';
import PostQuiz from 'src/pages/PostQuiz/PostQuiz';
import Success from 'src/pages/Success/Success';
import Terms from 'src/pages/Terms/Terms';
import Mail from 'src/pages/Mail/Mail';
import ContactUs from 'src/pages/ContactUs/ContactUs';
import FAQ from 'src/pages/FAQ/FAQ';
import { ROUTES } from 'src/constants/routes';
import SubscriptionTerms from 'src/pages/SubscriptionTerms';
import Header from '../Header/Header';
import PopUpContainer from '../PopUpContainer/PopUpContainer';
import CookiesAgreement from '../CookiesAgreement';
import styles from './Main.module.scss';
import { useMain } from './useMain';

const Main = () => {
  useMain();

  return (
    <div className={styles.container}>
      <div className={styles.app}>
        <Header />
        <PopUpContainer />
        <div className={styles.content}>
          <Switch>
            <Route path={ROUTES.FLOW} exact component={Flow} />
            <Route path={ROUTES.GENDER} exact component={Gender} />
            <Route path={ROUTES.OVERTHINK} exact component={Overthink} />
            <Route path={ROUTES.DETAILS} exact component={Details} />
            <Route path={ROUTES.TRUST} exact component={Trust} />
            <Route path={ROUTES.MAIL} exact component={Mail} />
            <Route path={ROUTES.PROCESSING} exact component={Processing} />
            <Route path={ROUTES.POST_QUIZ} exact component={PostQuiz} />
            <Route path={ROUTES.SUCCESS} exact component={Success} />
            <Route path={ROUTES.TERMS} exact component={Terms} />
            <Route path={ROUTES.FAQ} exact component={FAQ} />
            <Route path={ROUTES.CONTACT_US} exact component={ContactUs} />
            <Route
              path={ROUTES.SUBSCRIPTION_TERMS}
              exact
              component={SubscriptionTerms}
            />
          </Switch>
        </div>
        <CookiesAgreement />
      </div>
    </div>
  );
};

export default Main;
