import { useEffect, useState } from 'react';
import { Store } from 'redux';
import { useLocation } from 'react-router-dom';
import { appHeight } from 'src/utils/appHeight';
import { initServices, Services } from 'src/services';
import { RootInitialState } from 'src/redux/store/rootReducer';
import createStore from 'src/redux/store/store';

export const useApp = () => {
  const location = useLocation();
  const [appStore, setAppStore] = useState<Store<RootInitialState>>();
  const [appServices, setAppServices] = useState<Services>();
  const [isAppLoaded, setIsAppLoaded] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    appHeight();
    window.addEventListener('resize', appHeight);
    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, []);

  useEffect(() => {
    initServices()
      .then((services) => {
        const store = createStore(services);
        setAppServices(services);
        setAppStore(store);
        setIsAppLoaded(true);
      });
  }, []);

  useEffect(() => {
    appServices?.analyticsService.launch();
  }, [appServices]);

  return {
    store: appStore,
    services: appServices,
    isAppLoaded,
  };
};
