import React, { useEffect, useRef } from 'react';

const CardPayment = ({ formMerchantData }: { formMerchantData: unknown }) => {
  const form = useRef();

  const containerId = 'solid-payment-form-container';

  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    if (!form.current && formMerchantData && PaymentFormSdk) {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      form.current = PaymentFormSdk.init({
        merchantData: formMerchantData,
      });
    }
  }, [formMerchantData, form]);

  return <div title="card" id={containerId} />;
};

export default React.memo(CardPayment);
