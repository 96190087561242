export enum OrderStatus {
  APPROVED = 'approved',
  REFUNDED = 'refunded',
  DECLINED = 'declined',
  PROCESSING = 'processing',
}

export type TOrderResponse = {
  order: {
    status: OrderStatus;
    order_id: string;
  };
};

export type TOrderStatusMessage = {
  type: 'orderStatus';
  response: TOrderResponse
};

export type TCardMessage = {
  type: 'card';
  card: {
    brand: string;
  };
}

export type TMessageEventData = TOrderStatusMessage | TCardMessage;
