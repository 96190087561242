import { useEffect } from 'react';
import useServices from 'src/hooks/useServices';

export default function useGender() {
  const { analyticsService } = useServices();

  useEffect(() => {
    analyticsService.flowGenderOpen();
  }, [analyticsService]);
}
