import React from 'react';

import styles from './PackageDescription.module.scss';

const contents = [
  'Compatibility analysis for GEMINI and advice on building relationship with people',
  'Detailed guide to relationship with other signs',
  'Professional advice from astrologers',
  'Horoscope for 2022 for your sign',
  'Premium account in Astrology App (iOS and Android)',
];

const PackageDescription = () => (
  <div className={styles.container}>
    <h3 className={styles.title}>What’s inside?</h3>
    <ul className={styles.list}>
      {contents.map((text) => (
        <li key={text} className={styles.list_item}>
          {text}
        </li>
      ))}
    </ul>
  </div>
);

export default PackageDescription;
