import React from 'react';
import ReactDOM from 'react-dom';

import { useCookiesAgreement } from 'src/hooks/useCookiesAgreement';
import CookiesAgreementContent from './CookiesAgreementContent';

const CookiesAgreement = () => {
  const { isPrivateDataCollectionAgreed: isAgreed, agreePrivateDataCollection } = useCookiesAgreement();

  if (isAgreed) {
    return null;
  }

  return ReactDOM.createPortal(
    <CookiesAgreementContent onAccept={agreePrivateDataCollection} />,
    document.body,
  );
};

export default CookiesAgreement;
