import React, { MouseEventHandler } from 'react';
import { Link as RLink, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { ILink } from './ILink';
import styles from './Link.module.scss';

const Link: React.FC<ILink> = ({
  to,
  text,
  disabled,
  className = '',
  download,
  target,
}) => {
  const history = useHistory();
  const onClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();

    if (disabled) return;

    setTimeout(() => history.push(to), 300);
  };

  const classes = cn({
    [styles.link]: true,
    [styles.linkDisabled]: disabled,
    [className]: !!className,
  });

  console.log({
    download,
    target,
  });

  return (
    <RLink className={classes} to={to} onClick={onClick} download={download} target={target}>
      {text}
    </RLink>
  );
};

export default Link;
