import { createAsyncThunk } from '@reduxjs/toolkit';
import { Services } from 'src/services';
import { normalizeError } from 'src/utils/errors';
import { UserLocation } from '../entities';

export const fetchLocation = createAsyncThunk<
  UserLocation,
  void,
  { extra: Services }
>('location/FETCH_LOCATION', async (_, { extra, rejectWithValue }) => {
  const { locationService } = extra;

  try {
    return await locationService.getLocation();
  } catch (err) {
    const error = normalizeError(err);

    return rejectWithValue(error.message);
  }
});
