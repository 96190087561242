import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchLocation } from '../../location/store';

export function useMain() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocation());
  }, []);
}
