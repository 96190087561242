import React from 'react';

import styles from './EmailScreenSuggestionsList.module.scss';

const SUGGESTED_DOMAINS = [
  '@gmail.com',
  '@yahoo.com',
  '@hotmail.com',
  '@aol.com',
  '@outlook.com',
];

type EmailScreenSuggestionsListProps = {
  input: string;
  onSelect: (value: string) => void;
};

const EmailScreenSuggestionsList = ({ input, onSelect }: EmailScreenSuggestionsListProps) => {
  if (!input.length || input.includes('@')) {
    return null;
  }

  return (
    <div className={styles.list_container}>
      <div className={styles.list}>
        {
          SUGGESTED_DOMAINS.map((domain) => (
            <div key={domain} className={styles.list_item} onClick={() => onSelect(input + domain)}>
              {input + domain}
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default EmailScreenSuggestionsList;
