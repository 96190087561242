import { useEffect, useRef } from 'react';
import { TOrderResponse } from 'src/components/PostQuiz/Purchase/types';
import { IOrderLifecycleMethods } from 'src/components/PostQuiz/Purchase/IOrderLifecycleMethods';
import useServices from './useServices';

export default function usePaypalPayment({
  paypalPaymentUrl,
  onOrderStartProcessing,
  onOrderApproved,
  onOrderDeclined,
}: IOrderLifecycleMethods & { paypalPaymentUrl: string }) {
  const paypalRef = useRef<HTMLDivElement>(null);
  const paypalButtonRef = useRef<HTMLDivElement>(null);
  const { analyticsService } = useServices();

  useEffect(() => {
    if (paypalRef.current && paypalPaymentUrl) {
      const script = document.createElement('script');
      script.src = paypalPaymentUrl;
      script.type = 'text/javascript';
      script.async = true;
      script.setAttribute('data-btn-id', 'paypal-button');
      paypalRef.current?.appendChild(script);

      return () => {
        paypalRef.current?.removeChild(script);
      };
    }
    return () => {};
  }, [paypalRef.current, paypalPaymentUrl]);

  useEffect(() => {
    const buttonContainer = paypalButtonRef.current;
    if (!buttonContainer) {
      return () => {};
    }

    const eventListenersMap = {
      'order-started-processing': () => {
        analyticsService.paymentPaypalChosen();
        onOrderStartProcessing();
      },
      'order-processed': (event: Event) => {
        onOrderApproved((event as CustomEvent<{ data: TOrderResponse }>).detail.data.order.order_id);
      },
      'button-error': () => {
        onOrderDeclined();
      },
    };

    Object
      .entries(eventListenersMap)
      .forEach(([type, listener]) => buttonContainer.addEventListener(type, listener, false));

    return () => Object
      .entries(eventListenersMap)
      .forEach(([type, listener]) => buttonContainer.removeEventListener(type, listener, false));
  }, [
    paypalButtonRef.current,
    onOrderStartProcessing,
    onOrderApproved,
    onOrderDeclined,
  ]);

  return {
    paypalRef,
    paypalButtonRef,
  };
}
