/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { LocationCountry, LocationRegion, UserLocation } from '../entities';
import { LoadingStatuses } from '../../types';
import { fetchLocation } from './actions';

export type LocationState = {
  location: UserLocation;
  loadingsStatus: LoadingStatuses;
};

export const locationInitialState: LocationState = {
  location: {
    country: LocationCountry.UNKNOWN,
    region: LocationRegion.UNKNOWN,
  },
  loadingsStatus: LoadingStatuses.IDLE,
};

export const locationReducer = createReducer(locationInitialState, (builder) => {
  builder
    .addCase(fetchLocation.pending, (state) => {
      state.loadingsStatus = LoadingStatuses.PENDING;
    })
    .addCase(fetchLocation.fulfilled, (state, { payload }) => {
      state.location = payload;
      state.loadingsStatus = LoadingStatuses.FULFILLED;
    })
    .addCase(fetchLocation.rejected, (state) => {
      state.loadingsStatus = LoadingStatuses.FAILED;
    });
});
