import React, { FC } from 'react';
import { IPaypalPayment } from './IPaypalPayment';

import styles from './PaypalPayment.module.scss';

const PaypalPayment: FC<IPaypalPayment> = ({
  paypalRef,
  paypalButtonRef,
}) => (
  <div ref={paypalRef} className={styles.button}>
    <div id="paypal-button" ref={paypalButtonRef} />
  </div>
);

export default React.memo(PaypalPayment);
