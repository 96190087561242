import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core';
import styles from './ProgressBar.module.scss';
import { IProgressBar } from './IProgressBar';

const CustomLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  bar: {
    borderRadius: 15,
    background: '#FFF',
  },
})(LinearProgress);

const steps = 5;

const ProgressBar: React.FC<IProgressBar> = ({ step }) => {
  const progress = (step * 100) / steps;

  return (
    <CustomLinearProgress className={styles.progress} variant="determinate" value={progress} />
  );
};

export default ProgressBar;
