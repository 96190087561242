/* eslint-disable no-underscore-dangle */
import HttpClient from 'src/api/HttpClient';
import { BaseService } from './BaseService';

export class AuthService extends BaseService {
  private tokenExpireAtKey = 'ASK_Nebula_exp_at'

  private authStateChangeListeners: Array<(isAuthorized: boolean) => void> = [];

  private _isAuthorized = false;

  constructor(httpClient: HttpClient) {
    super(httpClient);
    this.httpClient.onUnauthorized(this.onUnauthorized.bind(this));
    this._isAuthorized = !this.isRefreshTokenExpired();
  }

  get isAuthorized(): boolean {
    return this._isAuthorized;
  }

  onAuthorizedStateChanged(listener: (isAuthorized: boolean) => void) {
    this.authStateChangeListeners.push(listener);
  }

  async logout() {
    await this.httpClient.post('/auth/signOut', {});
    this.maybeChangeAuthorizedState(false);
    this.storeTokenExpiration(0);
  }

  async signIn(email: string, password: string) {
    const res: {
      access_expires_at: number;
      refresh_expires_at: number
    } = await this.httpClient.post('/auth/signIn', { email, password });
    this.maybeChangeAuthorizedState(true);
    this.storeTokenExpiration(res.refresh_expires_at);
    return res;
  }

  async signUpViaQuiz(userInfo: any) {
    const res: {
      access_expires_at: number;
      refresh_expires_at: number
    } = await this.httpClient.post('/funnel/quiz/user', userInfo);
    this.maybeChangeAuthorizedState(true);
    this.storeTokenExpiration(res.refresh_expires_at);
    return res;
  }

  async confirmEmail(token: string) {
    return this.httpClient.post('/auth/confirm-email', {
      confirmation_token: token,
    });
  }

  async requestPasswordReset(email: string) {
    return this.httpClient.post('/auth/request-reset-token', { email });
  }

  async resetPassword(resetToken: string, password: string) {
    return this.httpClient.post('/auth/reset-password', {
      reset_token: resetToken,
      password,
    });
  }

  async authorizeWithPassword(password: string) {
    const res = await this.httpClient.post('/auth/password-authorize', {
      password,
    });

    if (!res || !res.success) throw Error('Not authorized');
  }

  private onUnauthorized() {
    this.maybeChangeAuthorizedState(false);
    this.storeTokenExpiration(0);
  }

  private maybeChangeAuthorizedState(isAuthorized: boolean) {
    if (isAuthorized === this._isAuthorized) return;

    this._isAuthorized = isAuthorized;
    this.notifyAuthorizedStateChanged();
  }

  private notifyAuthorizedStateChanged() {
    this.authStateChangeListeners.forEach((listener) => {
      try {
        listener(this._isAuthorized);
      } catch (err) {
        console.error(err);
      }
    });
  }

  private isRefreshTokenExpired(): boolean {
    return this.getStoredTokenExpiration() < Date.now();
  }

  private getStoredTokenExpiration() {
    const expireAtRaw = localStorage.getItem(this.tokenExpireAtKey) || '0';
    const expireAt = parseFloat(expireAtRaw);
    return Number.isNaN(expireAt) ? 0 : expireAt;
  }

  private storeTokenExpiration(expiredAt: number) {
    localStorage.setItem(this.tokenExpireAtKey, expiredAt.toString());
  }
}
