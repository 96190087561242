import React from 'react';
import { LocationCountry } from '../../entities';

export type LocationCountryCaseProps = {
  value?: LocationCountry;
  children?: React.ReactNode;
  default?: boolean;
};

const LocationCountryCase = ({ children }: LocationCountryCaseProps) => <>{children}</>;

export default LocationCountryCase;
