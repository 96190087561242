import amplitude from 'amplitude-js';

export class AnalyticsService {
  private instance: amplitude.AmplitudeClient;

  constructor() {
    this.instance = amplitude.getInstance();
    this.instance.init('');
  }

  launch() {
    if (!localStorage.getItem('launched')) {
      localStorage.setItem('launched', 'true');
      this.instance.logEvent('launch_first_time');
    } else if (!sessionStorage.getItem('launched')) {
      sessionStorage.setItem('launched', 'true');
      this.instance.logEvent('launch');
    }
  }

  flowStartOpen() {
    this.instance.logEvent('flow_start_open');
  }

  flowGenderOpen() {
    this.instance.logEvent('flow_gender_open');
  }

  flowNameOpen() {
    this.instance.logEvent('flow_name_open');
  }

  flowTestimonial1Open() {
    this.instance.logEvent('flow_testimonial1_open');
  }

  flowPartnerOpen() {
    this.instance.logEvent('flow_partner_open');
  }

  flowTestimonial2Open() {
    this.instance.logEvent('flow_testimonial2_open');
  }

  flowEmailFormOpen() {
    this.instance.logEvent('flow_emailform_open');
  }

  paymentCardChosen() {
    this.instance.logEvent('payment_card_chosen');
  }

  paymentPaypalChosen() {
    this.instance.logEvent('payment_paypal_chosen');
  }

  paymentError() {
    this.instance.logEvent('payment_error');
  }

  paymentSuccess({ productId }: { productId: string }) {
    this.instance.logEvent('payment_success', {
      product_id: productId,
    });
  }

  lpIntroOfferOpen() {
    this.instance.setUserProperties({
      landing_page_id: 'lp_intro_offer_1',
    });
    this.instance.logEvent('lp_introoffer_open');
  }

  lpIntroOfferStandardClick({ productId }: { productId: string }) {
    this.instance.logEvent('lp_introoffer_standard_click', {
      product_id: productId,
    });
  }

  lpIntroOfferGetStartedClick() {
    this.instance.logEvent('lp_introoffer_getstarted_click');
  }
}
