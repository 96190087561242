import { useEffect, useMemo, useState } from 'react';
import useGetParameter from './useGetParameter';

let timeout: any;

export default () => {
  const popupName = useGetParameter('popup');
  const [mountedPopup, setMountedPopup] = useState(popupName);

  useEffect(() => {
    if (popupName) {
      // eslint-disable-next-line no-unused-expressions
      timeout && clearTimeout(timeout);
      setMountedPopup(popupName);
    } else {
      timeout = setTimeout(() => {
        setMountedPopup(null);
      }, 300);
    }
  }, [popupName]);

  useEffect(() => () => {
    // eslint-disable-next-line no-unused-expressions
    timeout && clearTimeout(timeout);
  }, []);

  const isOpened = useMemo(() => Boolean(popupName), [popupName]);

  return {
    mountedPopup,
    isOpened,
  };
};
