import React from 'react';
import { ReactComponent as Img } from 'src/assets/images/quiz/trust.svg';
import QuizContent from 'src/components/QuizContent/QuizContent';
import MailComponent from 'src/components/Mail/Mail';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ROUTES } from '../../constants/routes';
import { updateUserPreferences } from '../../redux/user';

const Mail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmit = (data: { email: string; emailConsent: boolean; }) => {
    dispatch(updateUserPreferences(data));
    history.push(ROUTES.PROCESSING);
  };

  return (
    <QuizContent
      title="What is your email address?"
      img={<Img title="trust" />}
      step={5}
      routeBack={ROUTES.TRUST}
    >
      <MailComponent onSubmit={handleSubmit} />
    </QuizContent>
  );
};

export default Mail;
