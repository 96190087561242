import React from 'react';
import { Provider } from 'react-redux';
import { useApp } from 'src/hooks/useApp';
import { ServicesContext } from 'src/context';
import Main from '../Main';

const App = () => {
  const { isAppLoaded, store, services } = useApp();

  if (!isAppLoaded || !store || !services) {
    return <>Loading</>;
  }

  return (
    <Provider store={store}>
      <ServicesContext.Provider value={services}>
        <Main />
      </ServicesContext.Provider>
    </Provider>
  );
};

export default App;
