/* eslint-disable class-methods-use-this */
import Cookies from 'js-cookie';

export class CookiesService {
  private consentKey = 'CookieConsent';

  isPrivateDataCollectionAgreed(): boolean {
    try {
      const agreementState = Cookies.getJSON(this.consentKey);
      return !!(agreementState);
    } catch (err) {
      return false;
    }
  }

  agreePrivateDataCollection(): void {
    Cookies.set(this.consentKey, 'true', { expires: 365 });
  }
}
