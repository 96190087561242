import React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { useCookiesAgreement } from 'src/hooks/useCookiesAgreement';
import male from 'src/assets/images/quiz/iconMan.svg';
import female from 'src/assets/images/quiz/iconWoman.svg';
import nonBinary from 'src/assets/images/quiz/iconNonbinary.svg';
import { IName } from 'src/types/types';

import { IGenderChoice } from './IGenderChoice';
import styles from './GenderChoice.module.scss';

const GenderChoice: React.FC<IGenderChoice & IName> = ({ route }) => {
  // TODO change this for smth more officiant
  const { isPrivateDataCollectionAgreed } = useCookiesAgreement();
  const history = useHistory();

  const choiceClasses = cn({
    [styles.choice]: true,
    [styles.choiceDisabled]: !isPrivateDataCollectionAgreed,
  });

  const handleClick = () => {
    if (!isPrivateDataCollectionAgreed) return;
    setTimeout(() => history.push(route), 500);
  };

  return (
    <ul className={styles.choiceList}>
      <li className={choiceClasses} onClick={handleClick}>
        <img className={styles.choiceImg} src={male} alt="male" />
        <p className={styles.choiceText}>Male</p>
      </li>
      <li className={choiceClasses} onClick={handleClick}>
        <img className={styles.choiceImg} src={female} alt="female" />
        <p className={styles.choiceText}>Female</p>
      </li>
      <li className={choiceClasses} onClick={handleClick}>
        <img
          className={styles.choiceImg}
          src={nonBinary}
          alt="non binary"
        />
        <p className={styles.choiceText}>Non-binary</p>
      </li>
    </ul>
  );
};

export default GenderChoice;
