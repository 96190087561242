import React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { IButton } from './IButton';
import styles from './Button.module.scss';

const Button: React.FC<IButton> = ({
  text,
  link,
  disabled,
  onClick,
  className,
  wrapperClassName,
  type = 'button',
}) => {
  const history = useHistory();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (link) setTimeout(() => history.push(link), 300);
  };
  return (
    <div className={cn(styles.block, wrapperClassName)}>
      { /* eslint-disable react/button-has-type */ }
      <button
        className={cn(disabled ? styles.disabled : styles.button, className)}
        type={type}
        onClick={handleClick}
        disabled={disabled}
      >
        {text}
      </button>
    </div>
  );
};

export default Button;
