import { BaseService } from 'src/services/BaseService';
import HttpClient from 'src/api/HttpClient';
import { parseQueryParams } from 'src/utils/query';

import { LocationCountry, LocationRegion, UserLocation } from '../entities';

export class LocationService extends BaseService {
  private overriddenValues: Partial<UserLocation> = {};

  constructor(httpClient: HttpClient) {
    super(httpClient);
    this.loadFormQueryParams();
  }

  async getLocation(): Promise<UserLocation> {
    try {
      const data = await this.httpClient.get('/user/locale');

      return {
        region: this.overriddenValues.region || data.region,
        country: this.overriddenValues.country || data.country,
      };
    } catch (err) {
      return {
        country: LocationCountry.UNKNOWN,
        region: LocationRegion.UNKNOWN,
      };
    }
  }

  private loadFormQueryParams() {
    const queries = parseQueryParams() as Partial<UserLocation>;

    this.overriddenValues.region = queries.region;
    this.overriddenValues.country = queries.country;
  }
}
