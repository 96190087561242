import React from 'react';
import { ReactComponent as Img } from 'src/assets/images/quiz/overthink.svg';
import QuizContent from 'src/components/QuizContent/QuizContent';
import RadioChoice from 'src/components/RadioChoice/RadioChoice';
import PensiveImg from '../../assets/images/emojies/pensive.svg';
import NeutralImg from '../../assets/images/emojies/neutral.svg';
import RelievedImg from '../../assets/images/emojies/relieved.svg';

import { ROUTES } from '../../constants/routes';

const choices = [
  { text: 'Yes, pretty often', img: PensiveImg, id: 1 },
  { text: 'Maybe, sometimes', img: NeutralImg, id: 2 },
  { text: 'No, never', img: RelievedImg, id: 3 },
];

const Overthink = () => (
  <QuizContent
    title="Do you tend to feel lonely while surrounded with other people?"
    img={<Img title="overthink" />}
    radio
    step={2}
    routeBack={ROUTES.GENDER}
  >
    <RadioChoice route={ROUTES.DETAILS} choices={choices} name="overthink" />
  </QuizContent>
);
export default Overthink;
