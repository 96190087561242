/* eslint-disable quotes,react/no-unescaped-entities */
import React from 'react';
import styles from './FAQ.module.css';

const FAQ = () => (
  <section className={styles.faq}>
    <h2 className={styles.title}>Compatibility report</h2>
    <div className={styles.wrap}>
      <h3 className={styles.subtitle}>What happens after I order?</h3>
      <p className={styles.text}>
        After you place your order we get to work! Based on the questions you
        answered in the quiz, our algorithms will craft your compatibility
        report tailored to your unique natal chart. All the reports are verified
        by our astrologers before they are sent to you so you can be certain
        about the relationship guide and all the tips inside.
      </p>
      <h3 className={styles.subtitle}>When will I receive my plan?</h3>
      <p className={styles.text}>
        Your plan will be in your email inbox right after one of our astrologers
        approve it. Usually it happens within 1 hour after you place the order.
        In case you don’t find your plan, please make sure to check the Spam
        folder.
      </p>
      <h3 className={styles.subtitle}>Is this a one-time payment?</h3>
      <p className={styles.text}>
        Yes, it is a one-time payment. No subscriptions, additional charges or
        hidden fees will ever occur.
      </p>
      <h3 className={styles.subtitle}>
        What format does this report come in?
      </h3>
      <p className={styles.text}>The report comes in digital PDF format.</p>
      <h3 className={styles.subtitle}>Will you ship my report by mail?</h3>
      <p className={styles.text}>
        No physical product will be shipped. This is a digital file that comes
        in PDF format.
      </p>
    </div>

    <h2 className={styles.title}>Subscription-based product</h2>
    <h3 className={styles.subtitle}>
      How will I receive my personalised horoscope & compatibility report?
    </h3>
    <p className={styles.text}>
      You’ll receive a unique download link that will take you to the AppStore
      to download the app. Once the app is installed on your phone, you will
      need to sign into the app using your email or Apple ID. You will have
      unlimited access to all the features within the app for the duration of
      your paid period.
      <br />
      <br />
      If you experience any difficulties with downloading or accessing the app,
      please contact us at
      <a href="mailto:support@astro-star.live">
        {" "}
        support@astro-star.live
      </a>
    </p>
    <h3 className={styles.subtitle}>
      When will I receive my personalised horoscope & compatibility report?
    </h3>
    <p className={styles.text}>
      The unique download link will be generated right after your payment. The
      plan will become available as soon as you download the app using your
      unique link.
    </p>
    <h3 className={styles.subtitle}>Can I cancel my subscription?</h3>
    <p className={styles.text}>
      Your subscription renews automatically at the end of each period until you
      cancel. Note that deleting the app does not cancel your subscriptions. You
      may cancel a free trial or a subscription by following simple steps from
      our Subscription Terms.
    </p>
    <h3 className={styles.subtitle}>What will I have access to?</h3>
    <p className={styles.text}>
      You'll get access to the premium features of the Nebula App including
      personalised compatibility report, daily, weekly and yearly horoscopes,
      NebulaTalk forum and more.
    </p>
  </section>
);

export default FAQ;
