import React from 'react';
import cn from 'classnames';
import styles from './InlineLink.module.scss';

export type InlineLinkProps = {
  href: string;
  className?: string;
  target?: string;
  children: React.ReactElement | string;
};

const InlineLink: React.FC<InlineLinkProps> = ({
  href,
  className = '',
  target = '_blank',
  children,
}) => {
  const classes = cn({
    [styles.link]: true,
    [className]: !!className,
  });

  return (
    <a href={href} className={classes} target={target}>
      {children}
    </a>
  );
};

export default InlineLink;
