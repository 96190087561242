import React from 'react';
import cn from 'classnames';
import Address from 'src/components/Address';
import styles from './Copyright.module.scss';

const Copyright = ({ className = '' }) => (
  <div className={cn(styles.container, className)}>
    <Address />
    <p className={styles.copyright}>
      <a href="mailto:support@astro-star.live">
        {' '}
        support@astro-star.live
      </a>
    </p>
  </div>
);

export default Copyright;
