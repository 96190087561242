import { useSelector } from 'react-redux';
import { getLocation, getLocationLoadingStatus } from '../store/selectors';

export function useLocation() {
  const location = useSelector(getLocation);
  const locationLoadingStatus = useSelector(getLocationLoadingStatus);

  return {
    ...location,
    locationLoadingStatus,
  };
}
