import { useEffect } from 'react';
import { IOrderLifecycleMethods } from 'src/components/PostQuiz/Purchase/IOrderLifecycleMethods';
import { OrderStatus, TMessageEventData } from 'src/components/PostQuiz/Purchase/types';
import useServices from './useServices';

export default function useCardPayment({
  onOrderStartProcessing,
  onOrderApproved,
  onOrderDeclined,
}: IOrderLifecycleMethods) {
  const { analyticsService } = useServices();

  useEffect(() => {
    const listener = (event: MessageEvent<TMessageEventData>) => {
      if (!event) {
        return;
      }

      if (event.data.type !== 'orderStatus') {
        return;
      }

      const { order } = event.data.response;

      switch (order.status) {
        case OrderStatus.PROCESSING:
          analyticsService.paymentCardChosen();
          onOrderStartProcessing();
          break;
        case OrderStatus.APPROVED:
        case OrderStatus.REFUNDED:
          onOrderApproved(order.order_id);
          break;
        case OrderStatus.DECLINED:
          onOrderDeclined();
          break;
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [
    onOrderStartProcessing,
    onOrderApproved,
    onOrderDeclined,
  ]);
}
