export type UserLocation = {
  region: LocationRegion;
  country: LocationCountry;
};

export enum LocationRegion {
  UNKNOWN = 'UNKNOWN',
  CALIFORNIA = 'CA',
}

export enum LocationCountry {
  UNKNOWN = 'UNKNOWN',
  US = 'US',
  UA = 'UA',
  AT = 'AT',
  BG = 'BG',
  BE = 'BE',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  GB = 'GB',
  GR = 'GR',
  IE = 'IE',
  IT = 'IT',
  HR = 'HR',
  HU = 'HU',
  EE = 'EE',
  ES = 'ES',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  MT = 'MT',
  NL = 'NL',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  SI = 'SI',
  SK = 'SK',
  FI = 'FI',
  FR = 'FR',
  SE = 'SE',
}

export const europeanUnionCountries = [
  'AT',
  'BG',
  'BE',
  'CY',
  'CZ',
  'DE',
  'DK',
  'GB',
  'GR',
  'IE',
  'IT',
  'HR',
  'HU',
  'EE',
  'ES',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'FR',
  'SE',
];
