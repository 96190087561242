import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import logo from 'src/assets/images/lunar_logo.svg';
import styles from './Header.module.scss';
import HeaderMenu from './HeaderMenu';

const Header = () => (
  <div className={styles.header}>
    <NavLink to={ROUTES.FLOW}>
      <img className={styles.logo} src={logo} alt="Aeterna" />
    </NavLink>
    <HeaderMenu className={styles.menu} />
  </div>
);

export default Header;
