import React from 'react';
import ButtonBack from 'src/components/common/ButtonBack/ButtonBack';
import styles from './QuizTitle.module.scss';
import { IQuizTitle } from './IQuizTitle';

const QuizTitle: React.FC<IQuizTitle> = ({ title, img, routeBack }) => (
  <div className={styles.title}>
    <ButtonBack routeBack={routeBack} />
    <div className={styles.container}>
      <h1>{title}</h1>
      {img}
    </div>
  </div>
);

export default QuizTitle;
