import React from 'react';
import Title from 'src/components/PostQuiz/Title/Title';
import BuyNow from 'src/components/PostQuiz/BuyNow/BuyNow';
import Bonus from 'src/components/PostQuiz/Bonus/Bonus';
import Package from 'src/components/PostQuiz/Package/Package';
import Guarantee from 'src/components/PostQuiz/Guarantee/Guarantee';
import Advantages from 'src/components/PostQuiz/Advantages/Advantages';
import Satisfaction from 'src/components/PostQuiz/Satisfaction/Satisfaction';
import Copyright from 'src/components/Copyright/Copyright';
import styles from './PostQuiz.module.scss';

const PostQuiz = () => (
  <div className={styles.container}>
    <Title />
    <BuyNow />
    <Bonus />
    <Package />
    <Guarantee />
    <Advantages />
    <Satisfaction />
    <Copyright />
  </div>
);

export default PostQuiz;
