import React from 'react';
import { ReactComponent as Img } from 'src/assets/images/postQuiz/img-offer-2.svg';
import styles from './Bonus.module.scss';

const Bonus = () => (
  <div className={styles.container}>
    <h3 className={styles.title}>Amazing astrology bonus:</h3>
    <h3 className={styles.title}>2022 zodiac horoscope for GEMINI</h3>
    <Img className={styles.img} title="Offer" />
  </div>
);

export default Bonus;
