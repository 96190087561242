import React from 'react';
import { IAdvantagesItem } from './IAdvantagesItem';
import styles from './AdvantagesItem.module.scss';

const AdvantagesItem: React.FC<IAdvantagesItem> = ({
  title, image, text, alt,
}) => (
  <div className={styles.container}>
    <img src={image} alt={alt} className={styles.img} />
    <p className={styles.title}>{title}</p>
    <p className={styles.text}>{text}</p>
  </div>
);

export default AdvantagesItem;
