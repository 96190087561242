export enum ROUTES {
  FLOW = '/',
  GENDER = '/gender',
  TRUST = '/trust',
  MAIL = '/mail',
  OVERTHINK = '/overthink',
  DETAILS = '/details',
  PROCESSING = '/processing',
  POST_QUIZ = '/billing',
  SUCCESS = '/success',
  TERMS = '/terms',
  PRIVACY_POLICY = '/terms#privacy-policy',
  CONTACT_US = '/contact-us',
  FAQ = '/faq',
  SUBSCRIPTION_TERMS = '/subscription-terms',
}
