import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doAgreePrivateDataCollection, isPrivateDataCollectionAgreed } from 'src/redux/cookiesAgreement';

export function useCookiesAgreement() {
  const isAgreed = useSelector(isPrivateDataCollectionAgreed);
  const dispatch = useDispatch();

  const agreePrivateDataCollection = useCallback(() => {
    dispatch(doAgreePrivateDataCollection());
  }, [dispatch]);

  return {
    agreePrivateDataCollection,
    isPrivateDataCollectionAgreed: isAgreed,
  };
}
