import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import paymentFailed from 'src/assets/images/payment_failed.svg';
import { IPopUpProps } from 'src/components/PopUpContainer/IPopUpProps';

import styles from './PurchaseFailed.module.scss';

const PurchaseFailed: FC<IPopUpProps> = ({ isOpened, onClose }) => {
  const history = useHistory();

  const onTryAgain = () => {
    history.goBack();
  };

  return (
    <Modal
      className={styles.modal}
      open={isOpened}
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(60, 60, 60, 0.1)',
          backdropFilter: 'blur(10px)',
        },
      }}
    >
      <div className={styles.container}>
        <div className={styles.title}>
          Payment failed
        </div>
        <div className={styles.text_container}>
          <div className={styles.text}>
            Your transaction has failed due to some technical error.
            Please, try again.
          </div>
        </div>
        <div className={styles.icon_container}>
          <img
            alt="payment failed"
            src={paymentFailed}
            className={styles.icon}
            onClick={onClose}
          />
        </div>
        <button className={styles.button} type="button" onClick={onTryAgain}>Try again</button>
      </div>
    </Modal>
  );
};

export default PurchaseFailed;
