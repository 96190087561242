/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import cn from 'classnames';
import styles from './Input.module.scss';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: boolean;
  errorText?: string;
}

const Input: React.FC<InputProps> = ({
  error,
  errorText,
  className,
  ...rest
}) => (
  <>
    <input
      className={cn(error ? styles.error : styles.input, className)}
      {...rest}
    />
    <div className={styles.errorContainer}>
      {error && <p className={styles.errorText}>{errorText}</p>}
    </div>
  </>
);

export default React.memo(Input);
