import { createReducer } from '@reduxjs/toolkit';
import { updateUserPreferences } from './actions';

const initialState = {
  email: '',
  emailConsent: false,
};

export const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(updateUserPreferences, (state, { payload }) => ({
    ...state,
    email: payload.email,
    emailConsent: payload.emailConsent,
  }));
});
