import React from 'react';
import styles from './Gender.module.scss';
import GenderChoice from '../GenderChoice/GenderChoice';
import { IGender } from './IGender';
import { IName } from '../../types/types';

const Gender: React.FC<IGender & IName> = ({ route, text, name }) => (
  <>
    <p className={styles.text}>{text}</p>
    <GenderChoice route={route} name={name} />
  </>
);

export default Gender;
