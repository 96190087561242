import React from 'react';
import styles from './QuizContent.module.scss';
import QuizTitle from '../QuizTitle/QuizTitle';
import ProgressBar from '../ProgressBar/ProgressBar';
import { IQuizContent } from './IQuizContent';

const QuizContent: React.FC<IQuizContent> = ({
  title, img, routeBack, step, radio, children,
}) => (
  <div className={styles.container}>
    <div className={styles.block}>
      <div className={styles.mobile}>
        <ProgressBar step={step} />
      </div>
      <QuizTitle title={title} img={img} routeBack={routeBack} />
      <div className={styles.wrapper}>
        <div className={styles.desktop}>
          <ProgressBar step={step} />
        </div>
        <div className={radio ? styles.radioContent : styles.content}>{children}</div>
      </div>
    </div>
  </div>
);

export default QuizContent;
