import React from 'react';
import { ReactComponent as CookiesIcon } from 'src/assets/images/cookies.svg';
import Button from 'src/components/common/Button/Button';
import styles from './CookiesAgreementContent.module.scss';

const CookiesAgreementContent = ({
  onAccept,
}: { onAccept: () => void }) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.infoIconWrapper}>
          <CookiesIcon className={styles.infoIcon} />
        </div>
        <p className={styles.infoText}>
          We use cookies to improve your experience on our site and provide you with relevant advertising.
        </p>
      </div>
      <Button
        onClick={onAccept}
        wrapperClassName={styles.button_wrapper}
        className={styles.button}
        text="Accept all cookies"
      />
    </div>
  </div>
);

export default CookiesAgreementContent;
