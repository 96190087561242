import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Back } from 'src/assets/images/back.svg';
import styles from './ButtonBack.module.scss';
import { IButtonBack } from './IButtonBack';

const ButtonBack: React.FC<IButtonBack> = ({ secondary, routeBack }) => {
  const history = useHistory();
  const handleClick = () => {
    setTimeout(() => history.push(routeBack), 300);
  };
  return (
    <button
      type="button"
      onClick={handleClick}
      className={secondary ? styles.secondary : styles.btn}
    >
      <Back className={styles.img} title="back" />
    </button>
  );
};

export default ButtonBack;
